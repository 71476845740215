/* General Styles */
body {
  margin: 0;
  font-family: "OVO Circular", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #212529;
  box-sizing: border-box;
}

#root {
  max-width: 710px;
  min-width: 450px;
  margin: 0 auto;
  padding: 20px;
}

/* Header Styles */
.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: justify;
  padding: 20px;
}

.header h1 {
  margin: 10px 0;
  font-size: 48px;
  color: #00261c;
}

.header p {
  margin: 10px 0;
}

.header-step {
  padding-top: 10px;
  color: #2f3749;
  font-size: 18px;
}

/* Steps Styles */
.steps-description {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0;
}

.left {
  font-size: 28px;
}

.right {
  font-size: 14px;
}

.apply-for-scheme {
  font-size: 24px;
}

/* Input Styles */
.form-control-text-custom,
.form-control-input-custom {
  display: block;
  height: auto; /* Allow the height to adjust based on content */
  padding: 10px 15px; /* Increased padding for better touch interaction */
  font-size: 16px; /* Slightly larger font for readability */
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
}

.form-control-text-custom {
  width: 35%;
}

.form-control-input-custom {
  width: 25%;
}

/* Button Styles */
.back-button,
.submit-input {
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 50px;
  cursor: pointer;
  margin-top: 40px;
}

.back-button {
  background-color: white;
  color: black;
  border: 1px solid black;
  float: left;
}

.back-button:hover {
  background-color: gray;
}

.submit-input {
  background-color: #0a9828;
  color: white;
  border: none;
  float: right;
}

.submit-input:hover {
  background-color: #087d22;
}

/* Radio Button Styles */
.radio-container {
  display: flex;
  flex-direction: column;
}

.radio-option {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-bottom: 10px;
}

.radio-option input[type="radio"] {
  display: none;
}

.custom-radio {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  margin-right: 10px;
  position: relative;
}

.custom-radio::before {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: background-color 0.3s, transform 0.3s;
}

.radio-option input[type="radio"]:checked + .custom-radio::before {
  background-color: #0a9828;
  transform: translate(-50%, -50%) scale(1);
}

/* Footer Styles */
.thank-footer {
  display: block;
  padding: 30px 25px;
  font-size: 16px;
  margin-bottom: -80px;
  background-color: #f9f6f3;
}

.thank-footer-bottom {
  margin-top: 40px;
}

.footer-padding {
  margin-bottom: 5px;
}

/* Miscellaneous Styles */
.bordered-div {
  border: 1px solid #d4cfcb;
  border-radius: 0.7%;
  padding: 30px 30px 0 30px;
}

.description {
  font-size: 16px;
  margin-top: 10px;
}

.error-message {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.hyperlink:hover {
  color: #087d22;
}

.hyperlink-color {
  color: #0a9828;
}

.legal {
  padding: 50px 15px;
  font-size: 14px;
}

.legal p {
  margin-right: 1em;
}

.links {
  display: flex;
  margin-top: 70px;
  margin-bottom: -10px;
}

.logo-title {
  display: flex;
  align-items: center;
}

.logo-title svg {
  margin-right: 1em;
}

.brand-midnight {
  color: #00261c;
}

h1.logo-size {
  font-size: 44px; /* Adjust this value as needed */
}

/* Responsive Styles */
@media (max-width: 768px) {
  #root {
    max-width: 100%;
    min-width: 0;
    padding: 10px;
  }

  .header h1 {
    font-size: 36px;
  }

  .steps {
    flex-direction: column;
    align-items: flex-start;
  }

  .steps-description {
    flex-direction: column;
  }

  .back-button,
  .submit-input {
    width: 100%;
    margin-top: 20px;
  }

  .back-button {
    float: none;
  }

  .submit-input {
    float: none;
  }

  .form-control-text-custom {
    width: 100%; /* Width for smaller screens (mobile) */
    padding: 12px 20px; /* Increased padding for better touch targets */
    font-size: 18px; /* Increased font size for readability */
  }

  .form-control-input-custom {
    width: 35%; /* Width for smaller screens (mobile) */
    padding: 12px 20px; /* Increased padding for better touch targets */
    font-size: 18px; /* Increased font size for readability */
  }
}
